<template>
  <div id="detail-scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <div>
          <!-- title -->
          <b-card-title class="d-flex align-items-center">
            <i class="bi bi-info-circle"></i>
            <span style="margin-left: 5px">
              Detail {{ status ? "Status " + status : "" }} pada Scraper
              "<strong>{{ scraper }}</strong
              >"
            </span>
          </b-card-title>
        </div>
        <!-- filter component -->
        <div class="d-flex">
          <!-- back button -->
          <b-button variant="outline-info" @click="goBack()">
            <feather-icon icon="ArrowLeftCircleIcon" /> Kembali
          </b-button>
          <!-- back button -->
          <b-button
            :variant="is_exclude_completed ? 'success' : 'outline-success'"
            class="ml-1 btn-icon"
            v-b-tooltip.hover.top="'Exclude Status Completed'"
            @click="(is_exclude_completed = !is_exclude_completed), getData()"
          >
            <i class="bi bi-check-circle" style="font-size: 20px;"></i>
          </b-button>
          <!-- dataRangePicker component -->
          <daterange-picker-component
            class="ml-1"
            :from_date="from_date"
            :to_date="to_date"
          />
          <!-- keyword filter -->
          <b-input-group class="input-group-merge ml-1" style="width: 15rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="table_data.filter"
              style="height: 3rem"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table component -->
      <table-component v-else :data="table_data" class="mt-1" />
    </b-card>
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import dataTableClientPagination from "@/views/components/dataTableClientPagination.vue";
import dateRangePicker from "@/views/components/dateRangePicker.vue";
import loader from "@/views/components/loader.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import moment from "moment";

export default {
  name: "ScraperDetail",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "table-component": dataTableClientPagination,
    "loader-component": loader,
    "daterange-picker-component": dateRangePicker,
  },
  data() {
    return {
      is_loading: true,
      is_exclude_completed: true,
      scraper: this.$route.query.scraper,
      status: this.$route.query.status,
      from_date: moment(new Date())
        .subtract(7, "days")
        .format("YYYY-MM-DD 00:00:00"),
      to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
        },
        filter: "",
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "scraper_name",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "status",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "7%" },
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "username",
            label: "pic",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "solution",
            label: "solusi",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "duration",
            label: "durasi",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
        items: null,
      },
      cancelToken: null,
    };
  },
  watch: {
    "$route.query"() {
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.is_loading = true;
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = axios.CancelToken.source();
      let params = {
        is_exclude_completed: this.is_exclude_completed,
        from_date: this.from_date,
        to_date: this.to_date,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL +
        "scraper-log/detail/" +
        this.$route.query.scraper +
        "?" +
        query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelToken.token })
        .then((res) => {
          this.table_data.items = (res.data.scraper_logs_data || []).sort(
            (a, b) => {
              return new Date(b.start) - new Date(a.start);
            }
          );
          this.table_data.page_info.total = this.table_data.items.length;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    goBack() {
      if (this.status == "Error") {
        this.$router.push("/error");
      } else if (this.status == "Unavailable") {
        this.$router.push("/unavailable");
      } else if (this.status == "Redirected") {
        this.$router.push("/redirected");
      } else if (this.status == "Missing Class") {
        this.$router.push("/missingclass");
      } else if (this.status == "No Article") {
        this.$router.push("/no-article");
      } else {
        this.$router.push("/scraper");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
