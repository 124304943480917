<template>
  <date-picker
    v-model="filter_date"
    range
    :clearable="false"
    type="datetime"
    popup-class="rounded mt-1"
    input-class="input bg-transparent form-control clickable"
    style="width: 25rem"
    placeholder="25 Aug 2022 00:00:00 - 29 Aug 2022 23:59:59"
    confirm
    show-time-header
    time-title-format="HH : mm : ss"
    range-separator=" - "
    confirm-text="Cari"
    format="DD MMM YYYY HH:mm:ss"
    @change="setQuery"
  >
    <!-- custom icon calendar -->
    <template #icon-calendar>
      <feather-icon icon="CalendarIcon icon" />
    </template>

    <!-- custom shortcuts in footer -->
    <template #footer>
      <div class="row mt-1">
        <!-- LEFT ROW -->
        <div class="col-md-6">
          <!-- SHORTCUT HARI INI -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="info"
              class="btn-outline-info badge-glow clickable w-100 btn-if-dark"
              @click="setToday"
            >
              <span>Hari Ini</span>
            </b-badge>
          </div>

          <!-- SHORTCUT KEMARIN -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="info"
              class="btn-outline-info badge-glow clickable w-100 btn-if-dark"
              @click="setYesterday"
            >
              Kemarin
            </b-badge>
          </div>

          <!-- SHORTCUT 7 HARI TERAKHIR -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="info"
              class="btn-outline-info badge-glow clickable w-100 btn-if-dark"
              @click="setOneWeekAgo"
            >
              7 Hari Terakhir
            </b-badge>
          </div>
        </div>

        <!-- RIGHT ROW -->
        <div class="col-md-6">
          <!-- SHORTCUT BULAN INI -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="info"
              class="btn-outline-info badge-glow clickable w-100 btn-if-dark"
              @click="setThisMonth"
            >
              Bulan Ini
            </b-badge>
          </div>

          <!-- SHORTCUT 1 BULAN TERAKHIR -->
          <div class="row mb-1 mx-auto shortcut">
            <b-badge
              pill
              variant="info"
              class="btn-outline-info badge-glow clickable w-100 btn-if-dark"
              @click="setOneMonthAgo"
            >
              1 Bulan Terakhir
            </b-badge>
          </div>

          <!-- SHORTCUT 3 BULAN TERAKHIR -->
          <div class="row mx-auto shortcut">
            <b-badge
              pill
              variant="info"
              class="btn-outline-info badge-glow clickable w-100 btn-if-dark"
              @click="setThreeMonthAgo"
            >
              3 Bulan Terakhir
            </b-badge>
          </div>
        </div>
      </div>
    </template>
  </date-picker>
</template>

<script>
import $ from "jquery";
import { BButton, BBadge } from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import cookie from "js-cookie";

export default {
  name: "dateRangePicker",
  components: {
    BButton,
    BBadge,
    DatePicker,
  },
  props: {
    from_date: {
      type: String,
      required: true,
    },
    to_date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      filter_date: [new Date(this.from_date), new Date(this.to_date)],
    };
  },

  mounted() {
    this.setQuery();
  },

  methods: {
    // SET DATE TO THIS DAY
    setToday() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setHours(0);
      start_date.setMinutes(0);
      start_date.setSeconds(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      end_date.setSeconds(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO YESTERDAY
    setYesterday() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setDate(start_date.getDate() - 1);
      end_date.setDate(end_date.getDate() - 1);
      start_date.setHours(0);
      start_date.setMinutes(0);
      start_date.setSeconds(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      end_date.setSeconds(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO 7 DAYS AGO
    setOneWeekAgo() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setDate(start_date.getDate() - 7);
      start_date.setHours(0);
      start_date.setMinutes(0);
      start_date.setSeconds(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      end_date.setSeconds(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO THIS MONTH
    setThisMonth() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setMonth(start_date.getMonth());
      start_date.setDate(1);
      start_date.setHours(0);
      start_date.setMinutes(0);
      start_date.setSeconds(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      end_date.setSeconds(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO ONE MONTH AGO - THIS DAY
    setOneMonthAgo() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setMonth(start_date.getMonth() - 1);
      start_date.setHours(0);
      start_date.setMinutes(0);
      start_date.setSeconds(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      end_date.setSeconds(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // SET DATE TO THREE MONTH AGO - THIS DAY
    setThreeMonthAgo() {
      const start_date = new Date();
      const end_date = new Date();
      start_date.setMonth(start_date.getMonth() - 3);
      start_date.setHours(0);
      start_date.setMinutes(0);
      start_date.setSeconds(0);
      end_date.setHours(23);
      end_date.setMinutes(59);
      end_date.setSeconds(59);
      return (this.filter_date = [start_date, end_date]);
    },
    // set query url
    setQuery() {
      let params = {
        from_date: moment(this.filter_date[0]).format("YYYY-MM-DD HH:mm:ss"),
        to_date: moment(this.filter_date[1]).format("YYYY-MM-DD HH:mm:ss"),
      };
      let new_query = JSON.parse(JSON.stringify(this.$route.query));
      for (let key in params) {
        new_query[key] = params[key];
      }
      if ($.param(this.$route.query) != $.param(new_query)) {
        this.$router.replace({ query: new_query });
      }
      if (this.$route.query.page) {
        this.updateQuery("page", 1);
      }
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
  },
};
</script>

<style>
.shortcut :hover {
  opacity: 0.5;
}
.input {
  border-radius: 5px;
  border-style: solid !important;
  border-width: 1px;
  height: 42px;
}
.icon {
  color: #6e6b7b !important;
}
.mx-date-row .active {
  background-color: #00cfe8 !important;
  color: white !important;
  border-radius: 50% !important;
}
.mx-date-row :hover {
  background-color: #eae9f7 !important;
  color: white !important;
  border-radius: 50% !important;
}
.mx-date-row .in-range {
  width: 5px !important;
  border-radius: 50% !important;
}
.mx-date-row .hover-in-range {
  width: 5px !important;
  border-radius: 50% !important;
}
.mx-time-list .active {
  color: #00cfe8 !important;
}
.mx-date-row .today {
  color: #1f25dd !important;
}
.dark-layout * .mx-datepicker .mx-input-wrapper .input {
  color: #b8c2cc !important;
}
.dark-layout * .mx-datepicker .mx-icon-calendar {
  color: #b8c2cc !important;
}
.dark-layout * .btn-if-dark {
  color: #00cfe8 !important;
}
</style>
